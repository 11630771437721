import React from 'react'

const Footer = () => {
  return (
    <div className='text-center mt-4 footer'>
      &copy; {new Date().getFullYear()} Copyright:{' Omar Ambriz'}
    </div>
  )
}

export default Footer