import { Formik, Form } from "formik";
import { TextField, TextArea } from "./TextField";
import * as Yup from "yup";
import { PersistFormikValues } from "formik-persist-values";
import Swal from "sweetalert2";





const ContactForm = () => {
  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }
  const validate = Yup.object({
    name: Yup.string()
      .trim()
      .min(2, "2 characters minimun")
      .max(30, "30 characters maximum")
      .required("Your name is required"),
    email: Yup.string().email("Invalid E-mail").required("E-mail is required"),
    comment: Yup.string()
      .trim()
      .min(10, "Please type a longer message")
      .max(600, "Too many characters, keep it 600 or less")
      .required("Required"),
  });

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        comment: "",
      }}
      validationSchema={validate}
      onSubmit={(values, actions) => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact-form", ...values })
        })
        .then(response => {
        if (response.ok) {
          console.log(response);
          Swal.fire({
            title: "Success",
            text: "Message sent!",
            icon: "success",
            confirmButtonText: "ok",
            confirmButtonColor: "#0b7276"
          });
        }
        else if (!response.ok){
          Swal.fire({
            title: "Error!",
            text: "Message not sent",
            icon: "error",
            confirmButtonText: "Close",
            confirmButtonColor: "#0b7276"
          });
        }
      })
      .catch(error => {
        
        console.log(error);
      });
      actions.resetForm({

        name: "",
        email: "",
        comment: "",
      })
  }}
    >
      {formik => (
        <div className='container col-md-6 mb-4' id="contact">
          <h1 className='font-weight-bold-display-4 text-center'>Contact</h1>
          <Form className="contact-form-div">
          <input type="hidden" name="contact-form" value="contact"/>
            <TextField 
              label='Name'
              name='name'
              type='text'
              placeholder='Name'
              
            />
            <TextField
              label='E-mail'
              name='email'
              type='email'
              placeholder='E-mail@email.com'
            />
            <TextArea
              label='Message'
              name='comment'
              type='textarea'
              placeholder='Message'
            />
            <button className='btn btn-success m-1' type='submit'>
              Send message
            </button>
            <button className='btn btn-danger m-2' type='reset'>
              Reset form
            </button>
            <PersistFormikValues/>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default ContactForm;