import React from 'react';

const ResponsiveIframe = ({ src }) => {
  return (
    <div className="embed-responsive embed-responsive-16by9">
      <iframe
        title="Embedded Content"
        className="embed-responsive-item"
        src={src}
        allowFullScreen
        style={{ width: '100%' }}

      />
    </div>
  );
};

export default ResponsiveIframe;
