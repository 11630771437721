import React from "react";
import ResponsiveIframe from "../components/ResponsiveIframe";
const Projects = () => {
  const iframeSrc = "https://www.muchachitosgardening.com";

  return (
    <div className="text-center m-4" id="projects">
      <h1>Projects</h1>
      <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-6">
      <div className="card" style={{ maxWidth: '500px' }}>
        <div className="card-body">
        <h3>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.muchachitosgardening.com"
          >
            Gardening Site
          </a>
        </h3>
        <ResponsiveIframe src={iframeSrc} />
        </div>
        </div>
        </div>
        </div>
      </div>
      <p className="mt-3">More projects comming soon!</p>
      <div className="spinner-border text-primary p-3 m-3" role="status"></div>
      <div
        className="spinner-border text-secondary p-3 m-3"
        role="status"
      ></div>
      <div className="spinner-border text-success p-3 m-3" role="status"></div>
      <div className="spinner-border text-danger p-3 m-3" role="status"></div>
      <div className="spinner-border text-warning p-3 m-3" role="status"></div>
      <div className="spinner-border text-info p-3 m-3" role="status"></div>
      <div className="spinner-border text-dark p-3 m-3" role="status"></div>
    </div>
  );
};

export default Projects;
