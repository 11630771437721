import React from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Oaimage from '../images/avatarme.jpg';
import { HashLink } from 'react-router-hash-link';




const Header = () => {



  return (
   

    <Navbar collapseOnSelect expand="sm" className='navbar-main'>
    <Container>
            <img src={Oaimage} alt="Avatar" className="my-img"/> 
                <h3 className='header-text'>Ambriz Dev</h3> 
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse className="justify-content-end">
        <Nav className="justify-content-end" >
                <HashLink className="nav-link p-3" smooth to="#aboutme" >About me</HashLink>
                <HashLink className="nav-link p-3" smooth to="#skills">Skills</HashLink>
                <HashLink className="nav-link p-3" smooth to="#projects">Projects</HashLink>
                <HashLink className="nav-link p-3" smooth to="#contact">Contact</HashLink>
         </Nav>
      </Navbar.Collapse>
    </Container>
    </Navbar>
  
  )
}

export default Header