import React from "react";
import { motion } from "framer-motion";
import AnimatedText from "./AnimatedText";


const TextMotion = () => {
 // const [replay, setReplay] = useState(true);
  // Placeholder text data, as if from API
  const placeholderText = [
    { type: "heading1", text: "Hello!" },
   
  ];

  const container = {
    visible: {
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  /* Quick and dirt for the example
  const handleReplay = () => {
    setReplay(!replay);
    setTimeout(() => {
      setReplay(true);
    }, 600);
  */

  return (
    <motion.div
      initial="hidden"
      // animate="visible"
      animate = 'visible'
      variants={container}
    >
      <div className="motion-container text-center">
        {placeholderText.map((item, index) => {
          return <AnimatedText {...item} key={index} />;
        })}
      </div>
    </motion.div>
  );
}

export default TextMotion