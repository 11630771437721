import React from "react";
import myImage from "../images/OmarAmbriz232.jpg";

const Aboutme = () => {
  return (
    <div className="container mb-4">
      <div className="container mb-4">
        <img className="myImage" src={myImage} alt="myself" />
      </div>
      <div id="aboutme">
        <p>
          {" "}
          Hello, welcome to my portfolio, my name is Omar Ambriz, I’m a front
          end web developer, since I was a kid I have alwayes been interested in
          computers and loved to use them, now I have the opportunity to be part
          of this field wich I really enjoy.
        </p>
        <p>
          Each project pushes me to learn something new and be better at what I
          do. I love the challenges this dymanic field puts me through. I also
          know the fundamentas of backend with node.js and python, as well as
          noSQL and SQL databases.
        </p>
        <p>
          Thanks for checking my portfolio site, don't hesitate to contact me.
        </p>
      </div>
    </div>
  );
};

export default Aboutme;
