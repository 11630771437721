import Contact from './views/Contact';
import {BrowserRouter} from 'react-router-dom'
import Skills from './views/Skills';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css'
import Header from './components/Header';
import Aboutme from './views/Aboutme';
import Button from './components/Button';
import TextMotion from './components/TextMotion';
import Projects from './views/Projects';



function App() {
  return (
    <div className='app-main-div'>
      <BrowserRouter >
        <Header/>
        <TextMotion />
        <Aboutme />
         <Button />
         <Skills/>
         <Projects />
         <Contact/>
         <Footer/>  
      </BrowserRouter>
    </div>
  );
}

export default App;
