import { useField, ErrorMessage } from "formik";
import '../'

export const TextField = ({label, ...props}) => {

    const [field, meta] = useField(props);  
      
    return (
            <div className="mb-3">
                <div className="form-floating">
 
                <input className={`form-control shadow-none input-box ${meta.touched && meta.error}`}  
                    autoComplete="off" {...field}{...props}/>
                <label htmlFor={field.name}>{label}</label>
                </div>
                <ErrorMessage component="div" className="error" name={field.name} />
            </div>
    )
}

export const TextArea = ({label, ...props}) => {

    const [field, meta] = useField(props);

    return (
        
        <div className="mt-1">
            <div className="form-floating">

            <textarea className={`form-control shadow-none input-box ${meta.touched && meta.error}`} 
                 autoComplete="off" {...field}{...props}/>
            <label htmlFor={field.name}>{label}</label>
                 </div>
            <ErrorMessage component="div" name={field.name} className="error" />
           
        </div>
        
    )
};