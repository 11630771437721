import React from 'react'
import js from '../languageImages/javascript1.png'
import bs from '../languageImages/bootstrap5.png'
import css3 from '../languageImages/css3.png'
import html5 from '../languageImages/html5.png'
import github from '../languageImages/Github1.png'
import nodejs from '../languageImages/Nodejspng.png'
import reactLogo from '../languageImages/reactlogo.png'
import pythonLogo from '../languageImages/pythonLogo1.png'
import djangoLogo from '../languageImages/Django2Logo.png'



const Skills= () => {


  
  return (
    <div className='container' id='skills'>
      <h1 className='text-center'>
        Skills
      </h1>
      <div className='container-md '>
        <div className='grid text-center'>
  
        <img className='js-logo img-fluid m-2' src={js} alt="javascript logo" />
        <img className='bootstrap-logo m-2' src={bs} alt="bootstrap log" />
        <img  className='css-logo m-2' src={css3} alt="css logo" />
        <img  className='github-logo' src={github} alt="github logo" />
        <img  className='html-logo m-2' src={html5} alt="html logo" />
        <img  className='nodejs-logo m-2' src={nodejs} alt="nodejs logo" />
        <img className='react-logo m-2' src={reactLogo} alt="react logo" />
        <img className='python-logo m-2' src={pythonLogo} alt="python logo" />
        <img className='django-logo m-2' src={djangoLogo} alt="django logo" />
        </div>
      </div>
      <p>
       
      </p>
    </div>
  )
}

export default Skills